export const ossList = [
  {
    name: '.NET core',
    versions: '6.0.5',
    licence: 'MIT',
    url: 'https://github.com/dotnet/core',
  },
  {
    name: 'IdentityServer4',
    licence: 'Apache 2.0',
    url: 'https://github.com/IdentityServer/IdentityServer4/',
  },
  {
    name: 'IdentityModel',
    versions: '6.0.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/IdentityModel/IdentityModel',
  },
  {
    name: 'IdentityModel.AspNetCore',
    versions: '4.2.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/IdentityModel/IdentityModel.AspNetCore',
  },
  {
    name: 'IdentityModel.OidcClient',
    licence: 'Apache 2.0',
    url: 'https://github.com/IdentityModel/IdentityModel.OidcClient',
  },
  {
    name: 'Npgsql',
    versions: '6.0.4',
    licence: 'PostgreSQL License',
    url: 'https://github.com/npgsql/npgsql',
  },
  {
    name: 'AutoFixture',
    versions: '4.14.0, 4.17.0',
    licence: 'MIT',
    url: 'https://github.com/AutoFixture/AutoFixture',
  },
  {
    name: 'Dapper',
    versions: '2.0.123',
    licence: 'Apache 2.0',
    url: 'https://github.com/DapperLib/Dapper',
  },
  {
    name: 'Coverlet',
    licence: 'MIT',
    url: 'https://github.com/coverlet-coverage/coverlet',
  },
  {
    name: 'FluentAssertions',
    licence: 'Apache 2.0',
    url: 'https://github.com/fluentassertions/fluentassertions',
  },
  {
    name: 'Xunit',
    licence: 'Apache 2.0 and MIT',
    url: 'https://github.com/xunit/xunit',
  },
  {
    name: 'TimeZoneConverter',
    licence: 'MIT',
    url: 'https://github.com/mattjohnsonpint/TimeZoneConverter',
  },
  {
    name: 'Swashbuckle.AspNetCore',
    licence: 'MIT',
    url: 'https://github.com/domaindrivendev/Swashbuckle.AspNetCore',
  },
  {
    name: 'AWS SDK for .NET',
    versions: '3.7.10.5, 3.7.8.17, 3.7.11.11, 3.7.1.15',
    licence: 'Apache 2.0',
    url: 'https://github.com/aws/aws-sdk-net/',
  },
  {
    name: 'Westwind.AspNetCore.Markdown',
    versions: '3.8.0',
    licence: 'MIT',
    url: 'https://github.com/RickStrahl/Westwind.AspNetCore.Markdown',
  },
  {
    name: 'TimeZoneConverter',
    versions: '5.0.0',
    licence: 'MIT',
    url: 'https://github.com/mattjohnsonpint/TimeZoneConverter',
  },
  {
    name: 'Castle Core',
    versions: '5.0.0',
    licence: 'Apache 2.0',
    url: 'http://www.castleproject.org/',
  },
  {
    name: 'Google API .NET client',
    versions: '1.54.0, 1.54.0.2430',
    licence: 'Apache 2.0',
    url: 'https://github.com/googleapis/google-api-dotnet-client',
  },
  {
    name: 'AWS Logger Core',
    versions: '3.1.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/aws/aws-logging-dotnet',
  },
  {
    name: 'DocumentFormat.OpenXml',
    versions: '2.16.0',
    licence: 'MIT',
    url: 'https://github.com/OfficeDev/Open-XML-SDK',
  },
  {
    name: 'SkiaSharp',
    versions: '2.80.3',
    licence: 'MIT',
    url: 'https://github.com/mono/SkiaSharp',
  },
  {
    name: 'MailKit',
    versions: '3.1.1',
    licence: 'MIT',
    url: 'https://github.com/jstedfast/MimeKit',
  },
  {
    name: 'Firebase Admin',
    versions: '2.2.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/Firebase/firebase-admin-dotnet',
  },
  {
    name: 'Serilog',
    versions: '2.11.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/serilog/serilog',
  },
  {
    name: 'Serilog Sinks Console',
    versions: '4.0.1',
    licence: 'Apache 2.0',
    url: 'https://github.com/serilog/serilog-sinks-console',
  },
  {
    name: 'Serilog Sinks Debug',
    versions: '2.0.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/serilog/serilog-sinks-debug',
  },
  {
    name: 'Knex',
    versions: '0.21.21',
    licence: 'MIT',
    url: 'https://github.com/knex/knex',
  },
  {
    name: 'dotenv',
    versions: '8.6.0',
    licence: 'BSD 2-Clause "Simplified" License',
    url: 'https://github.com/motdotla/dotenv',
  },
  {
    name: 'pg',
    versions: '8.7.3',
    licence: 'MIT',
    url: 'https://github.com/brianc/node-postgres',
  },
  {
    name: 'Kotlin',
    versions: '1.3.61',
    licence: 'Apache 2.0',
    url: 'https://github.com/JetBrains/kotlin',
  },
  {
    name: 'PostgreSQL JDBC Driver',
    versions: '42.2.9',
    licence: 'BSD-2-Clause',
    url: 'https://github.com/pgjdbc/pgjdbc',
  },
  {
    name: "jOOQ's",
    versions: '3.12.3',
    licence: 'Apache 2.0',
    url: 'https://github.com/jOOQ/jOOQ',
  },
  {
    name: 'HikariCP',
    versions: '3.4.2',
    licence: 'Apache 2.0',
    url: 'https://github.com/brettwooldridge/HikariCP',
  },
  {
    name: 'Sentry',
    versions: '1.7.29',
    licence: 'MIT',
    url: 'https://github.com/getsentry/sentry-java',
  },
  {
    name: 'javax.annotation',
    versions: '1.3.2',
    licence: 'CDDL GPL 2.0',
    url: 'https://github.com/javaee/javax.annotation',
  },
  {
    name: 'metadata-extractor',
    versions: '2.12.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/drewnoakes/metadata-extractor',
  },
  {
    name: 'aws sdk java',
    versions: 1.11,
    licence: 'Apache 2.0',
    url: 'https://github.com/aws/aws-sdk-java',
  },
  {
    name: 'wizzardo http',
    versions: '0.3.1-SNAPSHOT',
    licence: 'MIT',
    url: 'https://github.com/wizzardo/webery',
  },
  {
    name: 'wizzardo spring',
    versions: '0.3-SNAPSHOT',
    licence: 'MIT',
    url: 'https://github.com/wizzardo/webery',
  },
  {
    name: 'wizzardo websocket client',
    versions: 0.2,
    licence: 'MIT',
    url: 'https://github.com/wizzardo/webery',
  },
  {
    name: 'wizzardo tools',
    versions: 0.23,
    licence: 'MIT',
    url: 'https://github.com/wizzardo/tools',
  },
  {
    name: 'wizzardo datadog-jvm',
    versions: 0.3,
    licence: 'MIT',
    url: 'https://github.com/wizzardo/datadog-jvm',
  },
  {
    name: 'logback-classic',
    versions: '1.2.3',
    licence: 'EPL 1.0 LGPL 2.1',
    url: 'https://mvnrepository.com/artifact/ch.qos.logback/logback-classic',
  },
  {
    name: 'slf4j',
    versions: '1.7.25',
    licence: 'MIT',
    url: 'https://github.com/qos-ch/slf4j',
  },
  {
    name: 'jcl-over-slf4j',
    versions: '1.7.25',
    licence: 'Apache 2.0',
    url: 'https://mvnrepository.com/artifact/org.slf4j/jcl-over-slf4j',
  },
  {
    name: 'jul-to-slf4j',
    versions: '1.7.25',
    licence: 'MIT',
    url: 'https://mvnrepository.com/artifact/org.slf4j/jul-to-slf4j/1.7.30',
  },
  {
    name: 'log4j-over-slf4j',
    versions: '1.7.25',
    licence: 'Apache 2.0',
    url: 'https://mvnrepository.com/artifact/org.slf4j/log4j-over-slf4j/1.7.36',
  },
  {
    name: 'jakarta.mail',
    versions: '1.6.5',
    licence: 'EDL 1.0 EPL 2.0',
    url: 'https://mvnrepository.com/artifact/com.sun.mail/jakarta.mail',
  },
  {
    name: 'core-js',
    versions: '3.21.1',
    licence: 'MIT',
    url: 'https://github.com/zloirock/core-js',
  },
  {
    name: 'qr-code-styling',
    versions: '1.6.0-rc.1',
    licence: 'MIT',
    url: 'https://github.com/kozakdenys/qr-code-styling',
  },
  {
    name: 'qrcode.vue',
    versions: '1.7.0',
    licence: 'MIT',
    url: 'https://github.com/scopewu/qrcode.vue',
  },
  {
    name: 'vue-advanced-cropper',
    versions: '1.10.1',
    licence: 'MIT',
    url: 'https://github.com/Norserium/vue-advanced-cropper',
  },
  {
    name: 'Vue.js',
    versions: '2.6.14',
    licence: 'MIT',
    url: 'https://github.com/vuejs/vue',
  },
  {
    name: 'vue-i18n',
    versions: '8.27.0',
    licence: 'MIT',
    url: 'https://github.com/kazupon/vue-i18n',
  },
  {
    name: 'vue-router',
    versions: '3.5.3',
    licence: 'MIT',
    url: 'https://github.com/vuejs/vue-router',
  },
  {
    name: 'Vuetify',
    versions: '2.6.3',
    licence: 'MIT',
    url: 'https://github.com/vuetifyjs/vuetify',
  },
  {
    name: 'MaterialDesign-Webfont',
    licence: 'Pictogrammers Free License',
    url: 'https://github.com/Templarian/MaterialDesign-Webfont',
  },
  {
    name: 'Cropper.js',
    versions: '1.5.9',
    licence: 'MIT',
    url: 'https://github.com/fengyuanchen/cropperjs',
  },
  {
    name: 'Bootstrap',
    versions: '3.3.7',
    licence: 'MIT',
    url: 'https://github.com/twbs/bootstrap',
  },
  {
    name: 'Jquery',
    versions: '2.2.0',
    licence: 'MIT',
    url: 'https://github.com/jquery/jquery-dist',
  },
  {
    name: 'jsPDF',
    versions: '2.3.1',
    licence: 'MIT',
    url: 'https://github.com/MrRio/jsPDF',
  },
  {
    name: 'jQuery Validation',
    versions: '1.14.0',
    licence: 'MIT',
    url: 'https://github.com/jquery-validation/jquery-validation',
  },
  {
    name: 'jQuery Unobtrusive Validation',
    versions: '1.14.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/aspnet/jquery-validation-unobtrusive',
  },
  {
    name: 'Nominatim',
    versions: '4.0.1',
    licence: 'GPLv2 (liberty 0)',
    url: 'https://github.com/osm-search/Nominatim',
  },
  {
    name: 'moment-timezone',
    versions: '0.5.34',
    licence: 'MIT',
    url: 'https://github.com/moment/moment-timezone/',
  },
  {
    name: 'eslint',
    versions: '6.8.0',
    licence: 'MIT',
    url: 'https://github.com/eslint/eslint',
  },
  {
    name: 'husky',
    versions: '7.0.4',
    licence: 'MIT',
    url: 'https://github.com/typicode/husky',
  },
  {
    name: 'prettier',
    versions: '2.5.1',
    licence: 'MIT',
    url: 'https://github.com/prettier/prettier',
  },
  {
    name: 'sass',
    versions: '1.32.0',
    licence: 'MIT',
    url: 'https://github.com/sass/dart-sass',
  },
  {
    name: 'Retrofit',
    versions: '2.6.1',
    licence: 'Apache 2.0',
    url: 'https://github.com/square/retrofit',
  },
  {
    name: 'okhttp',
    versions: '4.3.1',
    licence: 'Apache 2.0',
    url: 'https://github.com/square/okhttp',
  },
  {
    name: 'lottie-android',
    versions: '3.4.2',
    licence: 'Apache 2.0',
    url: 'https://github.com/airbnb/lottie-android',
  },
  {
    name: 'okio',
    versions: '2.4.3',
    licence: 'Apache 2.0',
    url: 'https://github.com/square/okio',
  },
  {
    name: 'shimmer-android',
    versions: '0.5.0',
    licence: 'BSD License',
    url: 'https://github.com/facebook/shimmer-android',
  },
  {
    name: 'AndroidPdfViewer',
    versions: '3.2.0-beta.1',
    licence: 'Apache 2.0',
    url: 'https://github.com/barteksc/AndroidPdfViewer',
  },
  {
    name: 'ProgressButton',
    versions: '2.1.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/razir/ProgressButton',
  },
  {
    name: 'AppAuth-Android',
    versions: '0.7.1',
    licence: 'Apache 2.0',
    url: 'https://github.com/openid/AppAuth-Android',
  },
  {
    name: 'Flutter',
    versions: '>=2.12.0 <3.0.0',
    licence: 'BSD 3-Clause',
    url: 'https://github.com/flutter/flutter',
  },
  {
    name: 'intl',
    versions: '0.17.0',
    licence: 'BSD-3-Clause',
    url: 'https://github.com/dart-lang/intl',
  },
  {
    name: 'GetX',
    versions: '4.1.4',
    licence: 'MIT',
    url: 'https://github.com/jonataslaw/getx',
  },
  {
    name: 'http',
    versions: '0.13.3',
    licence: 'BSD-3-Clause',
    url: 'https://github.com/dart-lang/http',
  },
  {
    name: 'Lottie-flutter',
    versions: '1.0.1',
    licence: 'MIT',
    url: 'https://github.com/xvrh/lottie-flutter',
  },
  {
    name: 'flutter_svg',
    versions: '0.21.0',
    licence: 'MIT',
    url: 'https://github.com/dnfield/flutter_svg',
  },
  {
    name: 'dartz',
    versions: '0.10.0',
    licence: 'MIT',
    url: 'https://github.com/spebbe/dartz',
  },
  {
    name: 'equatable',
    versions: '2.0.0',
    licence: 'MIT',
    url: 'https://github.com/felangel/equatable',
  },
  {
    name: 'SQLCipher for Android',
    versions: '4.5.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/sqlcipher/android-database-sqlcipher',
  },
  {
    name: 'Roboelectric',
    versions: '4.7.3',
    licence: 'Apache 2.0 and MIT',
    url: 'https://github.com/robolectric/robolectric',
  },
  {
    name: 'Mockito',
    versions: '4.2.0',
    licence: 'MIT',
    url: 'https://github.com/mockito/mockito',
  },
  {
    name: 'junit',
    versions: '4.13.2',
    licence: 'EPL 1.0',
    url: 'https://github.com/junit-team/junit4',
  },
  {
    name: 'kotlinfixture',
    versions: '1.2.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/appmattus/kotlinfixture',
  },
  {
    name: 'react-native-action-sheet',
    versions: '^3.13.0',
    licence: 'MIT',
    url: 'https://github.com/expo/react-native-action-sheet',
  },
  {
    name: 'vector-icons',
    versions: '^12.0.5',
    licence: 'MIT',
    url: 'https://github.com/expo/vector-icons',
  },
  {
    name: 'react-native-async-storage/async-storage',
    versions: '~1.15.0',
    licence: 'MIT',
    url: 'https://github.com/react-native-async-storage/async-storage',
  },
  {
    name: 'react-native-community/datetimepicker',
    versions: '4.0.0',
    licence: 'MIT',
    url: 'https://github.com/react-native-datetimepicker/datetimepicker',
  },
  {
    name: 'react-native-community/masked-view',
    versions: '0.1.10',
    licence: 'MIT',
    url: 'https://github.com/react-native-masked-view/masked-view',
  },
  {
    name: 'react-native-community/viewpager',
    versions: '5.0.11',
    licence: 'MIT',
    url: 'https://github.com/callstack/react-native-pager-view',
  },
  {
    name: 'react-navigation/bottom-tabs',
    versions: '^6.0.9',
    licence: 'MIT',
    url: 'https://github.com/react-navigation/react-navigation',
  },
  {
    name: 'react-navigation/native',
    versions: '^6.0.6',
    licence: 'MIT',
    url: 'https://github.com/react-navigation/react-navigation',
  },
  {
    name: 'react-navigation/stack',
    versions: '^6.0.11',
    licence: 'MIT',
    url: 'https://github.com/react-navigation/react-navigation',
  },
  {
    name: 'babel-preset-expo',
    versions: '^9.0.2',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'dayjs',
    versions: '^1.10.7',
    licence: 'MIT',
    url: 'https://github.com/iamkun/dayjs',
  },
  {
    name: 'expo',
    versions: '^44.0.4',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-analytics-amplitude',
    versions: '~11.1.0',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-app-loading',
    versions: '~1.3.0',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-application',
    versions: '~4.0.1',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-asset',
    versions: '~8.4.6',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-av',
    versions: '~10.2.0',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-camera',
    versions: '~12.1.2',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-constants',
    versions: '~13.0.1',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-device',
    versions: '~4.1.0',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-document-picker',
    versions: '~10.1.3',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-file-system',
    versions: '~13.1.4',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-font',
    versions: '^10.0.4',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-image-manipulator',
    versions: '~10.2.0',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-image-picker',
    versions: '~12.0.1',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-intent-launcher',
    versions: '~10.1.3',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-linking',
    versions: '~3.0.0',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-media-library',
    versions: '~14.0.0',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-notifications',
    versions: '~0.14.0',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-screen-orientation',
    versions: '~4.1.1',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-sensors',
    versions: '~11.1.0',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-splash-screen',
    versions: '~0.14.1',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-status-bar',
    versions: '~1.2.0',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'expo-updates',
    versions: '~0.11.7',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'filesize',
    versions: '^8.0.6',
    licence: 'BSD-3-Clause',
    url: 'https://github.com/avoidwork/filesize.js',
  },
  {
    name: 'lodash',
    versions: '^4.17.21',
    licence: 'MIT',
    url: 'https://github.com/lodash/lodash',
  },
  {
    name: 'prop-types',
    versions: '^15.8.1',
    licence: 'MIT',
    url: 'https://github.com/facebook/prop-types',
  },
  {
    name: 'react',
    versions: '17.0.2',
    licence: 'MIT',
    url: 'https://github.com/facebook/react/',
  },
  {
    name: 'react-dom',
    versions: '17.0.2',
    licence: 'MIT',
    url: 'https://github.com/facebook/react',
  },
  {
    name: 'react-native',
    versions: '~0.64.3',
    licence: 'MIT, CC-BY-4.0',
    url: 'https://github.com/facebook/react-native',
  },
  {
    name: 'react-native-actionsheet',
    versions: '^2.4.2',
    licence: 'MIT',
    url: 'https://github.com/beefe/react-native-actionsheet',
  },
  {
    name: 'react-native-draggable-flatlist',
    versions: '^3.0.4',
    licence: 'MIT',
    url: 'https://github.com/computerjazz/react-native-draggable-flatlist',
  },
  {
    name: 'react-native-floating-action',
    versions: '^1.22.0',
    licence: 'MIT',
    url: 'https://github.com/santomegonzalo/react-native-floating-action',
  },
  {
    name: 'react-native-gesture-handler',
    versions: '~2.1.0',
    licence: 'MIT',
    url: 'https://github.com/software-mansion/react-native-gesture-handler',
  },
  {
    name: 'react-native-icon-badge',
    versions: '^1.1.3',
    licence: 'ISC',
    url: 'https://github.com/yanqiw/react-native-icon-badge',
  },
  {
    name: 'react-native-indicators',
    versions: '^0.17.0',
    licence: 'BSD-3-Clause',
    url: 'https://github.com/n4kz/react-native-indicators',
  },
  {
    name: 'react-native-keyboard-aware-scroll-view',
    versions: '^0.9.5',
    licence: 'MIT',
    url: 'https://github.com/wix/react-native-keyboard-aware-scrollview',
  },
  {
    name: 'react-native-keyboard-spacer',
    versions: '^0.4.1',
    licence: 'MIT',
    url: 'https://github.com/Andr3wHur5t/react-native-keyboard-spacer#readme',
  },
  {
    name: 'react-native-mime-types',
    versions: '^2.3.0',
    licence: 'MIT',
    url: 'https://github.com/Liroo/react-native-mime-types',
  },
  {
    name: 'react-native-modal-datetime-picker',
    versions: '^13.0.1',
    licence: 'MIT',
    url: 'https://github.com/mmazzarolo/react-native-modal-datetime-picker',
  },
  {
    name: 'react-native-parsed-text',
    versions: '0.0.22',
    licence: 'MIT',
    url: 'https://github.com/taskrabbit/react-native-parsed-text',
  },
  {
    name: 'react-native-picker-select',
    versions: '^8.0.4',
    licence: 'MIT',
    url: 'https://github.com/lawnstarter/react-native-picker-select',
  },
  {
    name: 'react-native-reanimated',
    versions: '~2.3.1',
    licence: 'MIT',
    url: 'https://github.com/software-mansion/react-native-reanimated#readme',
  },
  {
    name: 'react-native-root-siblings',
    versions: '^4.1.1',
    licence: 'MIT',
    url: 'https://github.com/magicismight/react-native-root-siblings#readme',
  },
  {
    name: 'react-native-root-toast',
    versions: '^3.3.0',
    licence: 'MIT',
    url: 'https://github.com/magicismight/react-native-root-toast',
  },
  {
    name: 'react-native-safe-area-context',
    versions: '3.3.2',
    licence: 'MIT',
    url: 'https://github.com/th3rdwave/react-native-safe-area-context',
  },
  {
    name: 'react-native-screens',
    versions: '~3.10.1',
    licence: 'MIT',
    url: 'https://github.com/software-mansion/react-native-screens',
  },
  {
    name: 'react-native-svg',
    versions: '12.1.1',
    licence: 'MIT',
    url: 'https://github.com/react-native-community/react-native-svg',
  },
  {
    name: 'react-native-svg-transformer',
    versions: '^1.0.0',
    licence: 'MIT',
    url: 'https://github.com/kristerkari/react-native-svg-transformer',
  },
  {
    name: 'react-native-unimodules',
    versions: '~0.15.0',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'react-native-uuid-generator',
    versions: '^6.1.1',
    licence: 'MIT',
    url: 'https://github.com/Traviskn/react-native-uuid-generator',
  },
  {
    name: 'react-native-view-shot',
    versions: '3.1.2',
    licence: 'MIT',
    url: 'https://github.com/gre/react-native-view-shot',
  },
  {
    name: 'react-native-web',
    versions: '~0.17.5',
    licence: 'MIT',
    url: 'https://github.com/necolas/react-native-web',
  },
  {
    name: 'react-native-webview',
    versions: '11.15.0',
    licence: 'MIT',
    url: 'https://github.com/react-native-webview/react-native-webview',
  },
  {
    name: 'react-navigation',
    versions: '^4.4.4',
    licence: 'MIT',
    url: 'https://github.com/react-navigation/react-navigation',
  },
  {
    name: 'react-redux',
    versions: '^7.2.6',
    licence: 'MIT',
    url: 'https://github.com/reduxjs/react-redux',
  },
  {
    name: 'react-timer-mixin',
    versions: '^0.13.4',
    licence: 'MIT',
    url: 'https://github.com/reactjs/react-timer-mixin',
  },
  {
    name: 'react-ui-basics',
    versions: '0.0.142',
    licence: 'MIT',
    url: 'https://github.com/wizzardo/react-ui-basics',
  },
  {
    name: 'redux',
    versions: '^4.1.2',
    licence: 'MIT',
    url: 'https://github.com/reduxjs/redux',
  },
  {
    name: 'redux-persist',
    versions: '^6.0.0',
    licence: 'MIT',
    url: 'https://github.com/rt2zz/redux-persist',
  },
  {
    name: 'redux-thunk',
    versions: '^2.4.1',
    licence: 'MIT',
    url: 'https://github.com/reduxjs/redux-thunk',
  },
  {
    name: 'sentry-expo',
    versions: '~4.0.0',
    licence: 'MIT',
    url: 'https://github.com/expo/sentry-expo',
  },
  {
    name: 'underscore',
    versions: '^1.13.2',
    licence: 'MIT',
    url: 'https://github.com/jashkenas/underscore',
  },
  {
    name: 'expo-clipboard',
    versions: '~2.1.0',
    licence: 'MIT',
    url: 'https://github.com/expo/expo',
  },
  {
    name: 'datadog/browser-rum',
    versions: '4.2.0',
    licence: 'Apache-2.0',
    url: 'https://github.com/DataDog/browser-sdk',
  },
  {
    name: 'emotion/react',
    versions: '^11.7.1',
    licence: 'MIT',
    url: 'https://github.com/emotion-js/emotion/tree/main/packages/react',
  },
  {
    name: 'emotion/styled',
    versions: '^11.6.0',
    licence: 'MIT',
    url: 'https://github.com/emotion-js/emotion/tree/main/packages/styled',
  },
  {
    name: 'mui/material',
    versions: '^5.4.1',
    licence: 'MIT',
    url: 'https://github.com/mui/material-ui',
  },
  {
    name: 'sentry/browser',
    versions: '6.17.3',
    licence: 'BSD-3-Clause',
    url: 'https://github.com/getsentry/sentry-javascript',
  },
  {
    name: 'amplitude-js',
    versions: '8.16.1',
    licence: 'MIT',
    url: 'https://github.com/amplitude/amplitude-javascript',
  },
  {
    name: 'chart.js',
    versions: '3.7.0',
    licence: 'MIT',
    url: 'https://github.com/chartjs/Chart.js',
  },
  {
    name: 'core-js',
    versions: '3.20.3',
    licence: 'MIT',
    url: 'https://github.com/zloirock/core-js',
  },
  {
    name: 'dayjs',
    versions: '1.10.7',
    licence: 'MIT',
    url: 'https://github.com/iamkun/dayjs',
  },
  {
    name: 'npm-run-all',
    versions: '4.1.5',
    licence: 'MIT',
    url: 'https://github.com/mysticatea/npm-run-all',
  },
  {
    name: 'preact',
    versions: '10.6.5',
    licence: 'MIT',
    url: 'https://github.com/preactjs/preact',
  },
  {
    name: 'react',
    versions: '17.0.2',
    licence: 'MIT',
    url: 'https://github.com/facebook/react',
  },
  {
    name: 'react-chartjs-2',
    versions: '4.0.1',
    licence: 'MIT',
    url: 'https://github.com/reactchartjs/react-chartjs-2',
  },
  {
    name: 'react-dom',
    versions: '17.0.2',
    licence: 'MIT',
    url: 'https://github.com/facebook/react',
  },
  {
    name: 'react-redux',
    versions: '7.2.6',
    licence: 'MIT',
    url: 'https://github.com/reduxjs/react-redux',
  },
  {
    name: 'react-scripts',
    versions: '5.0.0',
    licence: 'MIT',
    url: 'https://github.com/facebook/create-react-app',
  },
  {
    name: 'react-sparklines',
    versions: '1.7.0',
    licence: 'MIT',
    url: 'https://github.com/borisyankov/react-sparklines',
  },
  {
    name: 'react-ui-basics',
    versions: '0.0.143',
    licence: 'MIT',
    url: 'https://github.com/wizzardo/react-ui-basics',
  },
  {
    name: 'redux',
    versions: '4.1.2',
    licence: 'MIT',
    url: 'https://github.com/reduxjs/redux',
  },
  {
    name: 'redux-thunk',
    versions: '2.4.1',
    licence: 'MIT',
    url: 'https://github.com/reduxjs/redux-thunk',
  },
  {
    name: 'symbol-observable',
    versions: '^4.0.0',
    licence: 'MIT',
    url: 'https://github.com/blesh/symbol-observable',
  },
  {
    name: 'nginx',
    versions: '1.22.0',
    licence: 'https://github.com/nginx/nginx/blob/master/docs/text/LICENSE',
    url: 'https://github.com/nginx/nginx',
  },
  {
    name: 'certbot',
    versions: '1.28.0',
    licence: 'https://github.com/certbot/certbot/blob/master/LICENSE.txt',
    url: 'https://github.com/certbot/certbot',
  },
  {
    name: 'backports.cached-property',
    versions: '1.0.2',
    licence: 'MIT',
    url: 'https://github.com/penguinolog/backports.cached_property',
  },
  {
    name: 'grpcio',
    versions: '1.47.0',
    licence: 'CC-BY-4.0, Apache 2.0',
    url: 'https://grpc.io/',
  },
  {
    name: 'grpcio-tools',
    versions: '1.47.0',
    licence: 'CC-BY-4.0, Apache 2.0',
    url: 'https://grpc.io/',
  },
  {
    name: 'overrides',
    versions: '6.1.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/mkorpela/overrides',
  },
  {
    name: 'protobuf',
    versions: '3.20.1',
    licence: 'https://github.com/protocolbuffers/protobuf/blob/main/LICENSE',
    url: 'https://developers.google.com/protocol-buffers/',
  },
  {
    name: 'robotframework',
    versions: '5.0.1',
    licence: 'Apache 2.0',
    url: 'https://robotframework.org/',
  },
  {
    name: 'robotframework-assertion-engine',
    versions: '0.5.1',
    licence: 'Apache 2.0',
    url: 'https://github.com/MarketSquare/AssertionEngine',
  },
  {
    name: 'robotframework-browser',
    versions: '13.2.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/MarketSquare/robotframework-browser',
  },
  {
    name: 'robotframework-pythonlibcore',
    versions: '3.0.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/robotframework/PythonLibCore',
  },
  {
    name: 'six',
    versions: '1.16.0',
    licence: 'MIT',
    url: 'https://github.com/benjaminp/six',
  },
  {
    name: 'typing-utils',
    versions: '0.1.0',
    licence: 'Apache 2.0',
    url: 'https://github.com/bojiang/typing_utils',
  },
  {
    name: 'typing_extensions',
    versions: '4.2.0',
    licence: 'Python Software Foundation License v2',
    url: 'https://github.com/python/typing_extensions',
  },
  {
    name: 'wrapt',
    versions: '1.14.1',
    licence: 'BSD 2-Clause "Simplified" License',
    url: 'https://github.com/GrahamDumpleton/wrapt',
  },
];
