<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="content"
        :search="search"
        class="elevation-1"
        :items-per-page="15"
      >
        <template #[`item.url`]="{ item }">
          <a :href="item.url" target="_blank" rel="noopener noreferrer">{{
            item.url
          }}</a>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { ossList } from './oss_2022';
export default {
  name: 'OssTable',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Versions', value: 'versions' },
        { text: 'Licence', value: 'licence' },
        { text: 'Url', value: 'url' },
      ],
      content: ossList,
    };
  },
};
</script>

<style></style>
